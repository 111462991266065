/* src/components/WagePercentage/WagePercentage.css */
.wage-percentage-container {
  flex: 100%; /* Ensure responsive width */
  max-width: 100%;
  background: #111c44; /* Background color to match the design */
  border-radius: 28px; /* Border radius to match the design */
  display: flex;
  flex-direction: column; /* Change to column to stack items vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  box-sizing: border-box; /* Ensure padding is included in the width and height */
}

.wage-percentage.text {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.label {
  color: #a3aed0; /* Font color */
  font-size: 12.81px; /* Font size */
  text-align: center;
  align-self: auto;
  font-family: 'Alexandria', sans-serif; /* Font family */
  font-weight: 200; /* Extra Light */
  margin-bottom: 5px; /* Margin bottom */
  line-height: 1; /* Line height */
}

.amount {
  color: white; /* Font color */
  font-size: 21.95px; /* Font size */
  font-family: 'Alexandria', sans-serif; /* Font family */
  font-weight: 400; /* Regular */
  line-height: 1; /* Line height */
}
