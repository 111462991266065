.ms-main-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.ms-dashboard {
  flex: 1;
  width: 100%;
  background-color: #060b1d;
  display: flex;
  flex-direction: column;
}

.ms-header-row {
  background-color: #060B1D;
  display: flex;
  color: #00a6fb;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.ms-date {
  position: absolute;
  right: 0px;
}

.dashboard-container {
  display: grid;
  gap: 16px;
  padding: 16px;
  background-color: #060B1D;
  grid-template-columns: repeat(auto-fill, minmax(369px, 1fr));
  box-sizing: border-box;
  width: calc(100% - 250px); /* Adjust width to avoid horizontal overflow */
}

@media (min-width: 768px) {
  .dashboard-container {
    margin-left: 250px; /* Adjust based on navbar width */
    grid-template-columns: repeat(auto-fill, minmax(369px, 1fr));
  }
}

@media (max-width: 768px) {
  .dashboard-container {
    grid-template-columns: repeat(auto-fill, minmax(369px, 1fr));
  }
}

@media (max-width: 480px) {
  .dashboard-container {
    grid-template-columns: repeat(auto-fill, minmax(365, 1fr));
  
  }
}

.tile-container {
  background-color: #111D44;
  color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-sizing: border-box;
}

.tile-header {
  display: flex;
  font-family: 'Alexandria', sans-serif;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
}

.tile-button {
  background: none;
  border: none;
  cursor: pointer;
}

.tile-content {
  margin-top: 8px;
  font-family: 'Alexandria', sans-serif;
  font-weight: 400;
}

.tile-grid {
  display: grid;
  grid-template-columns: 2fr 1fr; /* 2:1 column ratio */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  gap: 8px; /* Adjust gap as needed */
}

.tile-cell {
  display: flex;
  align-items: center;
  background-color: #111D44; /* Background color for cells */
  padding: 16px; /* Padding inside cells */
  border-radius: 8px; /* Rounded corners for cells */
  position: relative;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.tile-icon {
  margin-right: 8px; /* Adjust spacing between icon and text */
}

.tile-text {
  font-size: 12px;
  color: #A3AED0;
  font-family: 'Alexandria', sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

.tile-label {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-family: 'Alexandria', sans-serif;
  font-weight: 400;
  color: #A3AED0;
}

.tile-value {
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  font-family: 'Alexandria', sans-serif;
  font-weight: 400;
  margin-top: 4px;
}
