/* src/global-components/navbar/navbar.css */ 

/* Hamburger Menu */
.hamburger-menu {
  font-size: 30px;
  cursor: pointer;
  color: #00a6fb;
  background: none;
  border: none;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
}

/* Navbar Default Styling */
.navbar {
  position: fixed;
  top: 0;
  left: -250px; /* Hide the navbar initially */
  width: 250px;
  height: 100%;
  background-color: #07080d;
  overflow-x: hidden;
  transition: 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 999;
}

/* Show Navbar on Larger Screens */
@media (min-width: 768px) {
  .navbar {
      left: 0;
      transform: translateX(0);
      display: block;
  }
}

.navbar-static {
  position: fixed;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: #07080d;
  overflow-x: hidden;
  transition: 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 999;
}

.navbar.open {
  transform: translateX(0);
}

.navbar ul {
  list-style-type: none;
  padding: 0;
}

.navbar li {
  padding: 8px 16px;
}

.navbar li a {
  color: white;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center; /* Already set for vertical alignment */
  justify-content: space-between; /* New property for horizontal alignment */
  padding: 8px 14px; /* Adjust padding if needed */
}

.navbar-icon {
  margin-right: 0px;
  size: 14px;
}

.navbar li a:hover {
  background-color: #575757;
}

.navbar.open {
  left: 0;
}

.navbar-icon {
  align-items: left;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: white; /* Adjust color if needed */
}

.logout-btn {
  position: absolute; 
  bottom: 10px; /* Adjust position for bottom placement */
  right: 10px; /* Adjust position for right alignment */
  padding: 5px 10px; /* Adjust padding */
  border: none;
  background-color: #e74c3c; /* Adjust color for logout button */
  color: white;
  cursor: pointer;
}
