/* src/components/WeeklySales/WeeklySales.css */
.weekly-breakdown-container {
  width: 100%;
  height: 100%;
  background: #111c44;
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Ensure content expands to fit container */
  box-sizing: border-box;
}


.change {
  color: #5bb86f;
  font-size: 11.01px;
  font-family: 'Alexandra-ExtraLight', sans-serif;
  font-weight: 700;
}

.arrow {
  width: 27.24px;
  height: 20.59px;
}

.day-label {
  color: #a3aed0;
  font-size: 9px;
  font-family: 'Alexandra-ExtraLight', sans-serif;
  font-weight: 700;
  padding: 5px;
  text-align: center;
  margin-top: 8px;
}

.tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .weekly-breakdown-container {
    padding: 5px;
    margin: 5px 0;
    width: 100%;
    height: auto;
  }

  .label {
    font-size: 10px;
  }

  .amount {
    font-size: 20px;
  }

  .change {
    font-size: 9px;
  }

  .graph {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  .bars {
    height: 100px;
  }

  .bar {
    width: 8px;
  }
}