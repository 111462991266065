.labour-tile {
    padding: 20px;
    flex: 1;
    color: white;
    text-align: center;
    border-radius: 10px;
    background: #111C44;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
  .labour-tile h3 {
    margin: 0 0 10px;
    font-family: 'Alexandria', sans-serif; /* Font family */
    font-weight: 200; /* Extra Light */
    font-size: 12px;
  }
  
  .labour-tile p {
    margin: 0;
    font-size: 24px;
  }