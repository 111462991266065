.report-settings {
    background: #111D44;
    border-radius: 20px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    height: 230px;
    color: white;
    font-family: 'Alexandria', sans-serif;
    font-weight: 500 !important;
    margin: 0;
  }
  
  .settings-label {
    font-size: 32px;
    font-family: 'Alexandria', sans-serif;
  }
  
  .rep-set-content {
    display: grid;
    grid-template-columns: 240px 140px; /* Columns for labels and inputs */
    gap: 8px 17px; /* 8px gap between rows, 17px gap between columns */
    align-items: start; /* Align items to the start of each column */
  }
 
  .rep-set-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 12px;
  }

  .rep-set-list label {
    display: block;
    width: 128px;
    height: 48px;
    line-height: 48px; 
    font-family: 'Alexandria', sans-serif;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .rep-set-inputs input,
  .rep-set-inputs select {
    display: block;
    width: 200px;
    height: 48px;
    background: #111D44;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    padding: 2px 20px; /* Adjust padding to fit height */
    font-size: 14px !important;
    font-family: 'Alexandria', sans-serif !important;
    font-weight: 200 !important;
    margin-top: 10px; /* 5px lower than the list items */
    box-sizing: border-box;
  }
  
  .rep-set-inputs input::placeholder {
    color: white; /* Placeholder text color */
  }
  
  .input-wrapper {
    position: relative;
  }
  
  .percentage-symbol {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 10px;
    font-family: 'Alexandria', sans-serif;
    pointer-events: none; /* Make the symbol unclickable */
  }