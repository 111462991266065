/* src/premium-dashboards/LabourDashboard.css */
.main-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start; /* Adjust to start to align content at the top */
  padding: 10px;
  background-color: #060b1d;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  box-sizing: border-box; /* Include padding in width and height */
}

.header {
  display: flex;
  justify-content: center; /* Center content horizontally */
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  box-sizing: border-box;
}

.l-s-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust gap between rows */
  padding: 0;
  width: 100%;
}

.l-s-row-one, .l-s-row-two {
  display: flex;
  gap: 10px;
  margin-bottom: 0; /* Ensure no margin at the bottom */
}

.l-s-row-one > div, .l-s-row-two > div {
  flex: 1;
}

.labour-usage-graph {
  width: 100%;
  height: 40vh; /* Adjust the height as a percentage of the viewport height */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.shifts-table {
  width: 100%;
  margin-top: 0;
  padding: 0;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .labour-usage-graph {
    height: 35vh; /* Adjust height for smaller screens */
  }

  .shifts-table {
    margin-top: 10px; /* Adjust margin as needed */
  }
}

@media (max-width: 480px) {
  .labour-usage-graph {
    height: 30vh; /* Adjust height for very small screens */
  }

  .shifts-table {
    margin-top: 10px; /* Adjust margin as needed */
  }
}
