/* src/components/Dashboard/Dashboard.css */

/* Overall layout to include navbar */
.main-container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

/* Navbar Styling for visibility on larger screens */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #07080d;
  transition: 0.3s ease-in-out;
  z-index: 999;
}

@media (min-width: 768px) {
  .navbar {
      transform: translateX(0);
  }
}

.navbar.open {
  transform: translateX(0);
}

/* Main dashboard container */
.main-dashboard {
  display: grid;
  grid-template-rows: 10% 1fr; /* Header: 10%, Remaining content: 1fr */
  grid-template-columns: 100%; /* Single column for the entire width */
  width: 100%; /* Full width minus the navbar */
  padding: 10px;
  background-color: #060b1d;
  box-sizing: border-box;
  overflow-y: auto!important;
  height: 100%; /* Add this to ensure it takes the full viewport height */

}

@media (min-width: 768px) {
  .main-dashboard {
      margin-left: 250px; /* Adjust based on navbar width */
  }
}

/* Header Section */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

/* Remaining Content Section (Inner Grid) */
.content-container {
  display: grid;
  grid-template-rows: 2fr 1fr 2fr;
  grid-template-columns: 100%;
  height: 100%; /* Fills remaining space */
  width: 100%;
  box-sizing: border-box; /* Ensure padding and borders are included in the height and width */

}

.selectors-row {
  display: flex;
  color: #00a6fb;
  flex-direction: row;
  align-items: center;
}

.line {
  width: 100%;
  height: 4px;
  background-color: #1f2336;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.upper-dashboard {
  display: grid;
  padding: 10px;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
}

.middle-container {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.bottom-container {
  display: grid;
  padding: 10px;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
}

.data-column {
  display: flex;
  flex-direction: column;
  gap: 10px;  
}

.pie-container {
  display: flex;
  width: 100%;
  height: 100%;
  background: #111c44;
  border-radius: 24px;
  flex-direction: column;
  justify-content: left;
  box-sizing: border-box;
}

.custom-pie-chart {
  width: 80%;
  height: 80%;
  padding: 10px;
  align-self: center;
}

.pie-container canvas {
  max-width: 100%;
  max-height: 100%;
}

.two-tiles-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.graph {
  width: 100%;
  height: auto;
}

/* Adjustments for iPhone and smaller screens */
@media (max-width: 414px) {
  .main-dashboard {
      padding: 5px;
  }

  .header {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .logo {
      width: 30px;
      height: 30px;
  }

  .logout-button {
      font-size: 14px;
      margin-top: 10px;
  }

  .line {
      height: 2px;
  }

  .two-tiles-row {
      flex-direction: row;
      justify-content: center;
      gap: 10px;
  }

  .wage-percentage, .atv {
      flex: 1 1 calc(50% - 10px);
      max-width: calc(50% - 10px);
  }
}
