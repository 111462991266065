.ap-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Darken the backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it overlays other content */
  }
  
  .ap-modal-content {
    background: #111C44;
    border-radius: 28px;
    padding: 20px;
    width: 600px;
    position: relative;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
    animation: pop-in 0.3s ease-out; /* Add animation */
    z-index: 1001; /* Ensure it overlays the backdrop */
  }
  
  @keyframes pop-in {
    from {
      transform: scale(0.7);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .ap-title {
    height: 70px;
    text-align: center;
    color: white;
    font-size: 20px;
    font-family: "Alexandria-Regular", sans-serif;
    font-weight: 700;
    line-height: 28px;
    padding: 20px;
    background: #111C44;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .ap-subtitle {
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: "Alexandria-Regular", sans-serif;
    font-weight: 400;
    line-height: 10px;
  }
  
  .copy-button-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    margin-top: 10px;
  }
  
  .ap-copy-button {
    background: #00A6FB;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .ap-form-horizontal {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .ap-form-group {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  
  .ap-form-group label {
    color: white;
    align-self: flex-start;
    font-size: 14px;
    font-family: "Alexandria-Regular", sans-serif;
    font-weight: 700;
  }
  
  .ap-form-group input {
    border: none;
    border-radius: 5px;
    color: #000000;
    width: 100%;
    font-family: "Alexandria-Regular", sans-serif;
  }
  
  .ap-store-container {
    background: #111C44;
    border-radius: 28px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow */
    margin-top: 20px;
  }
  
  .ap-store-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
  }
  
  .ap-button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .ap-modal-close {
    background: #FF3333;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .ap-submit-button {
    background: #5BB86F;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Media Queries for Mobile Responsiveness */
  @media (max-width: 600px) {
    .ap-modal-content {
      width: 90%;
      padding: 10px;
    }
  
    .ap-form-group {
      width: 100%;
    }
  
    .ap-form-group label {
      font-size: 12px;
    }
  
    .ap-submit-button,
    .ap-modal-close {
      width: 100px;
      padding: 5px 10px;
      font-size: 12px;
    }
  }
  