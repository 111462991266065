.add-management-container {
    display: flex;
    flex-direction: column;
    background: #111D44;
    border-radius: 20px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    height: 230px;
    color: white;
    font-family: 'Alexandria', sans-serif;
    font-weight: 500 !important;
    margin: 0;
  }
  
  .user-management-btn {
    display: flex;
    align-items: center; /* Align items vertically center */
    width: 100%;
    max-width: 500px;
    height: 63px;
    margin-bottom: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #111D44;
    border: 1px solid white;
    padding: 10px;
  }
  
  .user-management-btn img {
    margin-right: 10px;
    height: 32px; /* Adjust size as needed */
  }
  
  .user-management-btn span {
    flex-grow: 1;
    text-align: center;
  }
  