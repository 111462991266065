.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ensure it takes full viewport height */
    background-color: #f8f8f8; /* Optional: Adding background color for better visibility */
    margin: 0;
  }
  
  .signup-page {
    width: 100%;
    max-width: 360px; /* Adjusted max-width for a smaller form */
    padding: 20px;
    background-color: #f8f8f8; 
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px; /* Optional: Adding border radius for a better look */
  }
  
  .hs-logo {
    width: 50%; /* Adjusted width for the logo */
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .CreateYourAccount {
    color: black;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    width: 100%; 
  }
  
  form {
    width: 100%; /* Ensure the form takes the full width */
  }
  
  .input-group {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the input group contents */
  }
  
  .input-group label {
    width: 100%; /* Make label take full width */
    color: #6F6F6F;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%; 
    height: 40px; /* Adjusted height */
    padding: 0 10px; /* Adjusted padding */
    background-color: #f8f8f8; 
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  .terms-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
  }
  
  .terms-container input {
    margin-right: 10px;
  }
  
  .terms-container a {
    color: #00A6FB;
    text-decoration: none;
  }
  
  .signup-btn {
    width: 100%;
    height: 42px;
    background: #00A6FB;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 16px;
  }
  
  .orSignupWith {
    color: #888888;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
    width: 100%; 
    text-align: center;
  }
  
  .social-login {
    display: flex;
    justify-content: space-around; /* Adjusted for multiple social buttons */
    width: 100%; 
    margin-bottom: 20px;
  }
  
  .social-btn {
    width: 40px;   /* Adjusted width for smaller social buttons */
    height: 40px; /* Adjusted height for smaller social buttons */
    background: #F4F4F4;
    border-radius: 5px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .social-logo {
    width: 20px;
    height: 20px;
  }
  
  .signin-link {
    color: #888888;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    width: 100%; 
    text-align: center;
  }
  
  .signin-link a {
    color: #00A6FB;
    text-decoration: none;
  }
  