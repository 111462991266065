/* src/integrations-page/integrations-page.css */
.main-container {
  display: flex;
}

/* Navbar Styling for visibility on larger screens */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #07080d;
  overflow-x: hidden;
  transition: 0.3s ease-in-out;
  z-index: 999;
}

.navbar.open {
  transform: translateX(0);
}

.main-dashboard {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.selectors-row {
  display: flex;
  color: #00a6fb;
  flex-direction: row;
  align-items: center;
}

.integrations-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal columns */
  grid-template-rows: repeat(4, 1fr);
  flex-wrap: wrap;
  gap: 20px;
  flex-grow: 1;
  justify-content: center;
}

/* Media Queries */
@media (min-width: 768px) {
  .navbar {
    transform: translateX(0);
  }
}

@media (max-width: 767px) {
  .integrations-grid {
    grid-template-columns: 1fr; /* One item per row */
    grid-template-rows: auto;
  }
}
