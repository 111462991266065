/* src/components/SiteSelector/SiteSelector.css */
.location-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 42px;
  background: #060b1d;
  border-radius: 28px;
  padding: 0 10px;
}

.logo {
  width: 30px;
  height: 30px;
}

.site-dropdown {
  flex-grow: 1;
  margin: 0 10px;
  height: 40px;
  background:   #060b1d;
  border-radius: 8px;
  border: 1px #e0e0e0 solid;
  color: #828282;
  font-size: 16px;
  font-family: 'Alexandria', sans-serif;
  font-weight: 500;
  text-align: center; /* Center text in the dropdown */
  text-align-last: center; /* Center selected text in the dropdown */
  padding: 0;
}

.site-dropdown option {
  text-align: center; /* Center text in the options */
}