/* src/components/LabourStats/LabourStats.css */

.l-s-container {
    display: grid;
    gap: 10px;
    padding: 10px;
  }
  
  .l-s-row-one, .l-s-row-two {
    display: grid;
    gap: 10px;
  }
  
  /* For larger screens, use 3 columns for the first row and 2 columns for the second row */
  @media (min-width: 769px) {
    .l-s-row-one {
      grid-template-columns: repeat(3, 1fr);
    }
  
    .l-s-row-two {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* For smaller screens, use 2 columns for both rows */
  @media (max-width: 768px) {
    .l-s-row-one, .l-s-row-two {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* For very small screens, stack tiles in a single column */
  @media (max-width: 480px) {
    .l-s-row-one, .l-s-row-two {
        grid-template-columns: repeat(2, 1fr);
    }
  }
  