/* src/components/SalesBreakdown/SalesBreakdown.css */
.graph-container {
  display: flex;
  width: 100%;
  height: 100%;
  background: #111c44;
  border-radius: 30px;
  flex-direction: column;
  justify-content: left;
  box-sizing: border-box;
}

.graph-label {
  display: flex;
  height: auto;
  width: 100%;
  padding-left: 25px;
  margin-top: 8px;
  align-content: flex-start;
  color: #a3aed0;
  font-size: 14px;
  font-family: 'Alexandria-Regular', sans-serif;
  font-weight: 500;
}

.graph-amount {
  display: flex;
  width: 100%;
  padding-left: 25px;
  align-content: flex-start;
  color: white;
  font-size: 24px;
  font-family: 'Alexandria-Regular', sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.difference {
  margin-top: 4px;
  font-size: 12px; /* Font size */
  font-family: 'Alexandria', sans-serif; /* Font family */
  font-weight: 400; /* Regular */
  line-height: 1; /* Line height */
}

.difference-positive {
  color: #5BB86F; /* Positive difference color */
}

.difference-negative {
  color: #FF3333; /* Negative difference color */
}

.sb-graph {
  width: 100%;
  max-width: 800px;
  align-items: center;
}

@media (max-width: 600px) {
  .label {
    font-size: 10px;
  }

  .amount {
    font-size: 20px;
  }

  .graph {
    width: 100%;
    height: 100%;
  }

  .bars {
    height: 100px;
  }

  .bar {
    width: 8px;
  }

  .hour-label {
    font-size: 9px;
  }
}
