/* src/global-components/TimesheetTable/TimesheetTable.css */
.shifts-table {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: #111C44;
    color: white;
    overflow: hidden; /* Ensure overflow is hidden to round borders correctly */
  }
  
  .table-header-container {
    background: #111C44;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .table-header {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
    font-size: 12px;
    background: inherit; /* Ensure the header has the same background */
  }
  
  .table-rows-container {
    overflow-y: auto; /* Make the rows container scrollable */
    max-height: 400px; /* Adjust as needed */
  }
  
  .table-row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }

  .header-cell {
    padding: 8px;
    padding-left: 16px;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
    font-size: 12px;
  }
  
  .table-cell {
    padding: 4px;
    padding-left: 16px;
    font-family: 'Alexandria', sans-serif;
    font-weight: 200;
    font-size: 12px;
  }
  
  @media (max-width: 768px) {
    .table-header, .table-row {
      grid-template-columns: repeat(4, 1fr);
    }
  
    .table-cell:nth-child(5),
    .table-cell:nth-child(6),
    .header-cell:nth-child(5),
    .header-cell:nth-child(6) {
      display: none;
    }
  }
  