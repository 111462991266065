.main-container {
    display: flex;
  }
  
  .main-settings {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    padding: 20px;
    background-color: #060B1D;
    box-sizing: border-box;
    overflow: hidden;
    position: relative; /* Added to position the save button */
  }
  
  @media (min-width: 768px) {
    .main-settings {
      margin-left: 250px; /* Adjust based on navbar width */
    }
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .settings-title {
    font-size: 40px;
    font-family: 'Alexandria', sans-serif;
  }
  
  .settings-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-grow: 1;
    margin-left: 30px;
    grid-gap: 0px;
    overflow-y: auto;
  }
  
  .save-button {
    background: #111D44;
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 14px;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  
  .settings-saved-banner {
    background-color: #5BB86F;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    font-family: 'Alexandria', sans-serif;
    font-weight: 700;
  }
  