/* src/components/DateSelector.css */
.date-selector {
  position: relative;
  display: flex;
  align-items: center;
}

.calendar-icon {
  font-size: 24px;
  cursor: pointer;
  color: #00a6fb;
  margin-right: 10px; /* Add some space between the icon and the custom input */
}

.custom-input {
  display: inline-block;
  padding: 8px 12px;
  background-color: #111c44;
  color: #a3aed0;
  border-radius: 8px;
  font-family: 'Alexandria', sans-serif;
  font-size: 16px;
  cursor: pointer;
}

/* Modal styles */
.dp-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other elements */
}

.dp-modal-content {
  background-color: #1f2336;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  position: relative;
}

.dp-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #a3aed0;
  font-size: 24px;
  cursor: pointer;
}

.react-datepicker {
  background-color: #1f2336;
  border: none;
  box-shadow: none;
}

.react-datepicker__header {
  background-color: #1f2336;
  border-bottom: none;
}

.react-datepicker__current-month,
.react-datepicker__day,
.react-datepicker__day-name {
  color: #a3aed0;
}

.react-datepicker__day--selected {
  background-color: #00a6fb;
  color: #ffffff;
}

.react-datepicker__day--keyboard-selected {
  background-color: #00a6fb;
  color: #ffffff;
}

.react-datepicker__day--outside-month {
  color: #444e72;
}
