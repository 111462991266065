.setup-company-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Ensure it takes full viewport height */
    background-color: #f8f8f8; 
    margin: 0;
  }
  
  .setup-company-page {
    width: 100%;
    max-width: 360px; /* Adjusted max-width for a smaller form */
    padding: 20px;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px; /* Optional: Adding border radius for a better look */
  }
  
  .hs-logo {
    width: 50%; /* Adjusted width for the logo */
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .SetupYourCompany {
    color: black;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    width: 100%; 
  }
  
  form {
    width: 100%; /* Ensure the form takes the full width */
  }
  
  .input-group {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the input group contents */
  }
  
  .input-group label {
    width: 100%; /* Make label take full width */
    color: #6F6F6F;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 5px;
  }
  
  .input-group input,
  .input-group select {
    width: 100%; 
    height: 40px; /* Adjusted height */
    padding: 0 10px; /* Adjusted padding */
    background-color: #f8f8f8;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  .signup-btn {
    width: 100%;
    height: 42px;
    background: #00A6FB;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 16px;
  }
  