/* src/index.css */

/* Base styles */

body, html, #root {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

button {
  cursor: pointer;
}

img, iframe, video {
  max-width: 100%; /* Ensure media elements are responsive */
  height: auto;
}

/* Container class to demonstrate responsive design */
.container {
  max-width: 1200px; /* Limit the maximum width */
  margin: 0 auto; /* Center the container */
  padding: 1rem; /* Add padding */
}

/* Media queries for tablets */
@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }
  h1 {
    font-size: 1.5rem;
  }
  button {
    font-size: 1rem;
  }
}

/* Media queries for mobile devices */
@media (max-width: 600px) {
  .container {
    padding: 0.5rem;
  }
  h1 {
    font-size: 1.2rem;
  }
  button {
    font-size: 0.9rem;
  }
}
