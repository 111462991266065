/* src/components/DateSelector.css */
.date-selector {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .calendar-icon {
    font-size: 24px;
    cursor: pointer;
    color: #00a6fb;
    margin-right: 10px;
  }
  
  /* Modal styles */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #1f2336;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #a3aed0;
    font-size: 24px;
    cursor: pointer;
  }
  
  .footer-buttons {
    text-align: right;
    color: #ffffff;
  }
  
  .footer-buttons .cancel-button,
  .footer-buttons .ok-button {
    background-color: #111c44;
    color: #a3aed0;
    border: none;
    padding: 8px 12px;
    margin: 5px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .footer-buttons .cancel-button:hover,
  .footer-buttons .ok-button:hover {
    background-color: #00a6fb;
    color: #ffffff;
  }
  
  .rs-picker-daterange,
  .rs-picker-daterange-menu,
  .rs-picker-daterange-panel {
    background-color: #1f2336;
    border: none;
    color: #a3aed0;
  }
  
  .rs-picker-toggle,
  .rs-picker-toggle-placeholder {
    background-color: #111c44;
    color: #a3aed0;
    padding: 8px 12px;
    border-radius: 8px;
  }
  
  .rs-picker-toggle:hover {
    background-color: #00a6fb;
    color: #ffffff;
  }
  
