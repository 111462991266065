.Premium-Graph {
    display: flex;
    justify-content: center;
    align-items: center; 
  }
    /* Adjustments for smaller screens */
    @media (max-width: 768px) {
      .Premium-Graph {
        height: 35vh; /* Adjust height for smaller screens */
      }
    }
    
    @media (max-width: 480px) {
      .Premium-Graph {
        height: 30vh; /* Adjust height for very small screens */
      }
    }