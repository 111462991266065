/* src/integrations-page/IntegrationTile.css */

.integration-tile {
    width: 100%; /* Default to 100% width for mobile */
    max-height: 300px; /* Set a max-height for the tiles */
    margin: 10px; /* Adjust as needed for spacing */
    position: relative;
    background: #111C44;
    border-radius: 20px;
    padding: 16px;
    color: white;
    font-family: 'Alexandria-Regular', sans-serif;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .integration-tile .int-header {
    display: flex;
    align-self: flex-start;
    align-items: center;
    margin-bottom: 10px; /* Adjust for spacing below the header */
  }
  
  .integration-tile .logo {
    width: 46px;
    height: 46px;
    border-radius: 20px;
  }
  
  .integration-tile .title {
    margin-left: 10px; /* Set title text 10px to the right of the logo */
    font-size: 16px;
    font-weight: 700;
    align-self: center; /* Center the title vertically with the logo */
  }
  
  .integration-tile .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }
  
  .integration-tile .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
  
  .integration-tile .status-indicator {
    display: flex;
    align-items: center;
  }
  
  .integration-tile .ellipse {
    width: 12px;
    height: 12px;
    background: #D9D9D9;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .integration-tile .status-text {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.60);
  }
  
  .integration-tile .connect-button {
    width: 100px;
    height: 28px;
    background: #00A6FB;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
  }
  
  .integration-tile .connect-button:hover {
    background: #0056b3;
  }

  .ellipse {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
    margin-right: 15px;
  }
  
  .ellipse.connected {
    background-color: #5BB86F;
  }
  
  