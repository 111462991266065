/* src/components/LabourUsageGraph/LabourUsageGraph.css */

.labour-usage-graph {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

  
  /* Adjustments for smaller screens */
  @media (max-width: 768px) {
    .labour-usage-graph {
      height: 35vh; /* Adjust height for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .labour-usage-graph {
      height: 30vh; /* Adjust height for very small screens */
    }
  }
  