.aba-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .aba-modal-content {
    background: #111C44;
    border-radius: 28px;
    padding: 20px;
    width: 600px;
    position: relative;
    max-height: 80vh;
    overflow-y: auto; 
  }
  
  .aba-title {
    height: 70px;
    text-align: center;
    color: white;
    font-size: 20px;
    font-family: "Alexandria-Regular", sans-serif;
    font-weight: 700;
    line-height: 28px;
    padding: 20px;
    background: #111C44;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .aba-subtitle {
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: "Alexandria-Regular", sans-serif;
    font-weight: 400;
    line-height: 10px;
  }

  .copy-button-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    margin-top: 10px;
  }
  
  .aba-copy-button {
    background: #00A6FB;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .aba-form-group {
    margin-top: 20px;
    background: #111C44;
    border-radius: 28px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 10px;
    width: 48%; /* Adjust width for side-by-side alignment */
    margin-right: 4%;
  }
  
  .aba-form-group:last-child {
    margin-right: 0;
  }
  
  .aba-form-group label {
    color: white;
    align-self: flex-start; 
    font-size: 14px;
    font-family: "Alexandria-Regular", sans-serif;
    font-weight: 700;
  }
  
  .aba-form-group input {
    border: none;
    border-radius: 5px;
    color: #000000;
    width: 100%;
    font-family: "Alexandria-Regular", sans-serif;
  }
  
  .aba-button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .aba-modal-close {
    background: #FF3333;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .aba-submit-button {
    background: #5BB86F;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Media Queries for Mobile Responsiveness */
  @media (max-width: 600px) {
    .aba-modal-content {
      width: 90%;
      padding: 10px;
    }
  
    .aba-form-group {
      width: 100%;
    }
  
    .aba-form-group label {
      font-size: 12px;
    }
  
    .aba-submit-button,
    .aba-modal-close {
      width: 100px;
      padding: 5px 10px;
      font-size: 12px;
    }
  }
  