/* src/styles/fonts.css */
@font-face {
    font-family: 'Alexandria Medium';
    src: url('/public/fonts/Alexandria-Medium.ttf') format('truetype');
}


@font-face {
    font-family: 'Alexandria ExtraLight';
    src: url('/public/fonts/Alexandria-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Alexandria Regular';
    src: url('/public/fonts/Alexandria-Regular.ttf') format('truetype');
}

body {
    font-family: 'Alexandria Regular', sans-serif;
}
