.mm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mm-modal-content {
  background: #111C44;
  border-radius: 28px;
  padding: 20px;
  padding-top: 0px;
  width: 600px;
  position: relative;
  max-height: 80vh;
  overflow-y: auto; 
}

.mm-title {
  height: 70px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-family: "Alexandria-Regular", sans-serif;
  font-weight: 700;
  line-height: 28px;
  background: #111C44;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mm-subtitle {
  text-align: center;
  color: white;
  font-size: 14px;
  font-family: "Alexandria-Regular", sans-serif;
  font-weight: 400;
  line-height: 10px;
}

.store-box {
  height: auto;
  margin-top: 20px;
  background: #111C44;
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px; /* Add padding to bottom */
}

.store-title {
  color: white;
  align-self: flex-start; /* Align title to the left */
  padding-bottom: 10px;
  font-size: 31.27px;
  font-family: "Alexandria-Regular", sans-serif;
  font-weight: 700;
  line-height: 38.62px;
}

.locations {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  width: 100%;
}

.location-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.location-item span {
  color: white;
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  margin-right: 10px;
  flex-grow: 1;
}

.toggle-container {
  display: inline-block;
  position: relative;
}

.location-item input[type="checkbox"] {
  display: none;
}

.mm-toggle {
  width: 40px;
  height: 14px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mm-rectangle {
  width: 40px;
  height: 13px;
  position: absolute;
  background: #444;
  border-radius: 38.57px;
  transition: background 0.3s;
}

.mm-ellipse {
  width: 14px;
  height: 14px;
  position: absolute;
  background: white;
  border-radius: 50%;
  left: 2px;
  top: 0.5;
  transition: transform 0.3s;
}

.location-item input[type="checkbox"]:checked + .mm-toggle .mm-rectangle {
  background: #7551FF;
}

.location-item input[type="checkbox"]:checked + .mm-toggle .mm-ellipse {
  transform: translateX(22px);
}

.toggle:hover .mm-rectangle {
  background: #555;
}

.location-item input[type="checkbox"]:checked + .mm-toggle:hover .mm-rectangle {
  background: #7551FF;
}

.modal-buttons {
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
  position: sticky; /* Keep buttons at the bottom */
  bottom: 0;
  background: #111C44; /* Match background color */
  padding: 10px 0;
}

.mm-button {
  width: 150px;
  height: 50px;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  font-family: "Alexandria-Regular", sans-serif;
  font-weight: 400;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}

.mm-save-button {
  background: #00A6FB;
}

.mm-reconnect-button {
  background: #5BB86F;
}

.mm-delete-button {
  background: #FF3333;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 600px) {
  .mm-modal-content {
    width: 90%;
    padding: 10px;
  }

  .locations {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .store-title {
    font-size: 24px; /* Adjusted font size for mobile */
  }

  .mm-title {
    font-size: 16px;
  }

  .mm-subtitle {
    font-size: 10px;
  }

  .mm-button {
    width: 100px; /* Adjusted button size for mobile */
    height: 40px;
    line-height: 40px;
    font-size: 10px;
  }
}
