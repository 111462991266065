.tile-container {
    background-color: #111D44;
    color: #fff;
    border-radius: 8px;
    width: 358px;
  }
  
  .tile-header {
    display: flex;
    font-family: 'Alexandria', sans-serif;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
  }
  
  .tile-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .tile-content {
    margin-top: 8px;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
  }
  
  .tile-grid {
    display: grid;
    grid-template-columns: 2fr 1fr; /* 2:1 column ratio */
    grid-template-rows: repeat(2, auto); /* 2 rows */
    gap: 8px; /* Adjust gap as needed */
  }
  

  .tile-cell {
    display: flex;
    align-items: center;
    background-color: #111D44; /* Background color for cells */
    padding: 16px; /* Padding inside cells */
    border-radius: 8px; /* Rounded corners for cells */
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  
  .tile-icon {
    margin-right: 8px; /* Adjust spacing between icon and text */
  }
  
  .tile-text {
    font-size: 12px;
    color: #A3AED0;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
    display: flex;
    flex-direction: column;
  }
  
  .tile-label {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
    color: #A3AED0;
  }
  
  .tile-value {
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
    margin-top: 4px;
  }