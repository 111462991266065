.p-tile-container {
    background-color: #111D44;
    color: #fff;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
  }
  
  .p-tile-header {
    display: flex;
    font-family: 'Alexandria', sans-serif;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
  }
  
  .tile-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .p-tile-content {
    margin-top: 8px;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
  }
  
  .p-tile-row-one{
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto); 
    gap: 8px; /* Adjust gap as needed */
  }
  

  .p-tile-cell {
    display: flex;
    align-items: center;
    background-color: #111D44; /* Background color for cells */
    padding: 16px; /* Padding inside cells */
    border-radius: 8px; /* Rounded corners for cells */
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  
  .p-tile-text {
    font-size: 12px;
    color: #A3AED0;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
    display: flex;
    flex-direction: column;
  }
  
  .tile-label {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
    color: #A3AED0;
  }
  
  .p-tile-value {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    font-family: 'Alexandria', sans-serif;
    font-weight: 400;
    margin-top: 4px;
  }